import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { createSetting } from 'api/requests';
import {
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
  GetAvailableTypesOutDto,
  LegalFramework as ILegalFramework,
  SubscriptionType,
} from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import PremiumFeatureBar from 'components/PremiumFeatureBar';
import { TrackingEvents, TrackingVariables, SCENARIO } from 'interfaces/enums';
import { ICreateSettingForm } from 'interfaces/requests/createSetting';
import { trackEvent } from 'lib/helpers/amplitude';
import { useEntitlement, useMessage, useNewConfigurations } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { AddDomain, Details, LegalFramework, Type } from './Steps';

const { useForm, useWatch } = Form;

interface ICreateConfigurationModal {
  open: boolean;
  companyId: string;
  canSelectLegalFramework: boolean;
  availableConfigurationsTypes: GetAvailableTypesOutDto;
  onClose: () => Promise<void>;
  allowedToCreateConfigByQuota: boolean;
  maxConfigsAllowed?: number;
  hasConfigurations?: boolean;
}

const CreateConfigurationModal = ({
  open,
  onClose,
  companyId,
  canSelectLegalFramework,
  availableConfigurationsTypes,
  allowedToCreateConfigByQuota,
  maxConfigsAllowed,
  hasConfigurations,
}: ICreateConfigurationModal) => {
  const [framework, setFramework] = useState<ILegalFramework>(ILegalFramework.GDPR);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledDomainsButton, setIsDisabledDomainsButton] = useState(true);
  const [type, setType] = useState<SubscriptionType>(SubscriptionType.web);
  const [detailsForm] = useForm<ICreateSettingForm>();
  const [domains, setDomains] = useState<string[]>([]);
  const detailsFormValues = useWatch([], detailsForm);
  const { markConfigurationsAsNew } = useNewConfigurations();
  const message = useMessage();
  const { subscription, entitlement } = useEntitlement();

  const { isAppAvailable, isWebAvailable } = availableConfigurationsTypes;
  const isV3 = entitlement?.['selected-app-version'] === 'v3';

  useEffect(() => {
    detailsForm.validateFields({ validateOnly: true }).then(
      () => {
        setIsDisabled(false);
      },
      () => {
        setIsDisabled(true);
      },
    );
  }, [detailsFormValues]);

  useEffect(() => {
    if (isAppAvailable && !isWebAvailable) {
      setType(SubscriptionType.app);
    }

    if (!isAppAvailable && isWebAvailable) {
      setType(SubscriptionType.web);
    }
  }, []);

  const onDomainsChange = useCallback(
    (domainsValue: string[]) => {
      if (domainsValue.length) {
        setIsDisabledDomainsButton(false);
        setDomains(domainsValue);
      } else {
        setIsDisabledDomainsButton(true);
      }
    },
    [setDomains],
  );

  const createConfiguration = async () => {
    if (!allowedToCreateConfigByQuota) {
      return;
    }

    const details = detailsForm.getFieldsValue();

    if (details) {
      const { domain, name, dataController } = details;
      try {
        const {
          body: { settingId },
        } = await createSetting({
          companyId,
          name,
          dataController,
          domains: domains.length ? domains : [domain],
          type,
          legalFramework: framework,
          firstLayerVariant: CreateSettingBodyFirstLayerVariant.WALL,
          buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant.fingerprint,
          buttonBackgroundColor: colors.primary,
          buttonTextColor: colors.white,
        });
        markConfigurationsAsNew([settingId]);
        /**
         * Tracking events
         */
        trackEvent(TrackingEvents.CONFIGURATION_CREATED, {
          [TrackingVariables.CONFIGURATION_FRAMEWORK]: framework,
          [TrackingVariables.PLATFORM]: type,
        });
        /**
         * ======
         */
        message.success('Configuration created successfully');
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    }
  };

  const premiumBarDescription =
    subscription?.inTrial && hasConfigurations
      ? 'You reached maximum number of 1 configuration on your trial, unlock full access by upgrading your account.'
      : `You reached maximum number of ${maxConfigsAllowed} configuration${
          maxConfigsAllowed === 1 ? '' : 's'
        }, unlock full access by upgrading your account.`;

  return (
    <StepModal
      open={open}
      title="Create Configuration"
      okText="Create Configuration"
      isConfirmation={false}
      width={760}
      onClose={onClose}
      banner={
        subscription &&
        (!allowedToCreateConfigByQuota || (subscription.inTrial && hasConfigurations)) && (
          <PremiumFeatureBar
            title="Maximum number of configurations reached"
            description={premiumBarDescription}
            buttonText={subscription.inTrial ? 'full-access' : 'upgrade'}
            subscriptionId={subscription.id}
            style={{ marginBottom: '24px' }}
            trackEvent={() => {
              trackEvent(TrackingEvents.UPGRADE_INITIATED, {
                [TrackingVariables.PLAN_NAME]: subscription.plan,
                [TrackingVariables.SCENARIO]: subscription.inTrial ? SCENARIO.TRIAL_UPGRADE : SCENARIO.PLAN_UPGRADE,
                [TrackingVariables.REFERRER_SECTION]: 'Create Configuration',
                [TrackingVariables.REFERRER_PAGE]: 'Configurations',
                [TrackingVariables.REFERRER_URL]: window.location.href,
              });
            }}
          />
        )
      }
      steps={[
        isV3
          ? {
              content: <AddDomain onDomainsChange={onDomainsChange} domains={domains} />,
              primaryButton: { disabled: isDisabledDomainsButton },
            }
          : undefined,
        canSelectLegalFramework
          ? {
              content: (
                <LegalFramework
                  framework={framework}
                  setFramework={setFramework}
                  disabled={(subscription?.inTrial && hasConfigurations) || !allowedToCreateConfigByQuota}
                />
              ),
              primaryButton: {
                disabled: (subscription?.inTrial && hasConfigurations) || !allowedToCreateConfigByQuota,
              },
            }
          : undefined,
        isAppAvailable && isWebAvailable
          ? {
              content: <Type setType={setType} type={type} />,
              primaryButton: {
                disabled: (subscription?.inTrial && hasConfigurations) || !allowedToCreateConfigByQuota,
              },
            }
          : undefined,
        {
          content: <Details type={type} form={detailsForm} />,
          handleNext: createConfiguration,
          primaryButton: {
            disabled: (subscription?.inTrial && hasConfigurations) || !allowedToCreateConfigByQuota || isDisabled,
          },
        },
      ]}
    />
  );
};

export default CreateConfigurationModal;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Flex, Form, FormInstance, Space } from 'antd';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router-dom';

import { createSetting, getMyAccounts } from 'api/requests';
import {
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
  LegalFramework,
  SubscriptionType,
} from 'api/requests/generated/generated.schemas';
import { createCustomerAndSubscription } from 'api/requests/onboarding';
import { postSurveyAnswers } from 'api/requests/survey';
import DotStepper from 'components/DotStepper';
import { Layout } from 'components/Layout/styled';
import { Title } from 'components/Typography';
import { TrackingEvents, TrackingVariables, Currency } from 'interfaces/enums';
import { ISurveyRequestAnswer } from 'interfaces/responses';
import { OnboardingSteps } from 'interfaces/steps';
import { ONBOARDING_TYPE, SURVEY_ID, USER_AUTH0_PARAMETERS } from 'lib/consts';
import { trackEvent } from 'lib/helpers/amplitude';
import { trackOnboardingSurveySubmitEvent } from 'lib/helpers/analytics';
import { clearTrialCookies } from 'lib/helpers/clearTrialCookies';
import { useMessage } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { UserDropdown, Sidebar, Footer } from './Layout';
import { ErrorState, LoadingState } from './Status';
import CustomizationStep from './Steps/Customization';
import Details from './Steps/Details';
import DetailsPlatform from './Steps/DetailsPlatform';
import Implementation from './Steps/Implementation';
import Survey from '../Survey';

const { Content } = Layout;

export interface ITrialDetailsForm {
  companyName: string;
  domain: string;
  framework: string;
}

export interface ITrialCustomisationForm {
  firstLayerVariant: CreateSettingBodyFirstLayerVariant;
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant;
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

const Onboarding = () => {
  const { user } = useAuth0();
  const [currentStep, setCurrentStep] = useState<OnboardingSteps>(OnboardingSteps.AccountDetails);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState<{ companyId: string; settingId: string } | null>(null);
  const [isError, setIsError] = useState(false);
  const [steps, setSteps] = useState<any>();
  const [breadCrumb, setBreadcrumb] = useState<string[]>([]);
  const [currentStepData, setCurrentStepData] = useState<any>(null);
  const [isAllowedToGoBack, setIsAllowedToGoBack] = useState(false);
  const message = useMessage();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [detailsForm] = Form.useForm<ITrialDetailsForm>();
  const [customisationForm] = Form.useForm<ITrialCustomisationForm>();
  const [surveyForm] = Form.useForm<FormInstance>();
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(SubscriptionType.web);

  useEffect(() => {
    if (user?.one_pricing_plan) {
      setSubscriptionType(SubscriptionType.web);
    } else if (user?.[USER_AUTH0_PARAMETERS.uc_subscription_type]) {
      setSubscriptionType(user[USER_AUTH0_PARAMETERS.uc_subscription_type]);
    } else {
      setSubscriptionType(SubscriptionType.web);
    }
  }, [user]);

  useEffect(() => {
    if (!state?.canBeCreated && !state?.hasAnySettings) {
      setCurrentStep(OnboardingSteps.Implementation);
      return;
    }
  }, []);

  useEffect(() => {
    if (currentStep === OnboardingSteps.Implementation) {
      setDisabled(false);
      return;
    }
  }, [currentStep]);

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const prepareAccount = async () => {
    try {
      const accountDetailsValues = detailsForm.getFieldsValue(true);

      const couponId =
        (user?.[USER_AUTH0_PARAMETERS.uc_onboarding] !== ONBOARDING_TYPE.STANDARD && user?.uc_coupon) || undefined;

      const { companyId, customerId } = await createCustomerAndSubscription({
        domain: accountDetailsValues.domain,
        companyName: accountDetailsValues.companyName,
        firstName: user?.given_name || user?.name || user?.nickname || '',
        lastName: user?.family_name || user?.middle_name || '',
        onboardingType: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        currency: accountDetailsValues.currency,
        ...accountDetailsValues.address,
        type: subscriptionType,
        couponId,
        leadSource: Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || undefined,
        ...(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan]
          ? { pricePlan: user?.[USER_AUTH0_PARAMETERS.one_pricing_plan] }
          : {}),
      });

      /**
       * Tracking events
       */
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.ONBOARDING_TRIAL_STARTED,
          [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.PLATFORM]: subscriptionType,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
          [TrackingVariables.COMPANY_ID]: companyId,
        },
      });
      trackEvent(TrackingEvents.ONBOARDING_TRIAL_STARTED, {
        [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        [TrackingVariables.PLATFORM]: subscriptionType,
        [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
          Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
        ),
        [TrackingVariables.COMPANY_ID]: companyId,
        [TrackingVariables.PLAN_NAME]: user?.one_pricing_plan ? 'One Pricing' : '',
      });
      /**
       * =====
       */

      return { companyId, customerId };
    } catch (error) {
      let messageText = error;
      if (error.statusCode === 400) {
        messageText = error.response?.data.error?.msg;
        message.error(messageText);
      } else if (typeof error === 'string') {
        message.error(error);
      }
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.ONBOARDING_TRIAL_START_FAILED,
          [TrackingVariables.ERROR]: messageText,
        },
      });
      trackEvent(TrackingEvents.ONBOARDING_TRIAL_START_FAILED, {
        [TrackingVariables.ERROR]: messageText,
      });
      setCurrentStep(OnboardingSteps.AccountDetails);

      return { companyId: '', customerId: '' };
    }
  };

  const prepareSetting = async (companyId: string) => {
    try {
      const frameworkName = detailsForm.getFieldValue('framework');
      const domain = detailsForm.getFieldValue('domain');

      setIsLoading(true);

      return createSetting({
        companyId,
        domains: [domain || ''],
        dataController: '',
        legalFramework: frameworkName,
        name: `${frameworkName} configuration #1`,
        type: subscriptionType,
        firstLayerVariant: customisationForm.getFieldValue('firstLayerVariant'),
        buttonPrivacyVariant: customisationForm.getFieldValue('buttonPrivacyVariant'),
        buttonBackgroundColor: customisationForm.getFieldValue('buttonBackgroundColor'),
        buttonTextColor: customisationForm.getFieldValue('buttonTextColor'),
      });
    } catch (error) {
      throw error;
    }
  };
  const submitSurvey = async (surveyId: string, ucBillingAccount?: string): Promise<void> => {
    const answers = Object.entries(surveyForm.getFieldsValue(true)).map(([questionId, value]) => ({
      questionId,
      value,
    })) as ISurveyRequestAnswer[];

    try {
      await postSurveyAnswers(surveyId, { answers, references: { ucBillingAccount } });
      trackOnboardingSurveySubmitEvent(answers);
    } catch (err) {
      setIsError(true);
      console.error(err);
    }
  };

  const onCreateAccount = async (): Promise<void> => {
    /**
     * Track events
     */
    TagManager.dataLayer({
      dataLayer: {
        event: TrackingEvents.ONBOARDING_SETUP_COMPLETED,
        [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        [TrackingVariables.PLATFORM]: subscriptionType,
        [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
          Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
        ),
      },
    });
    trackEvent(TrackingEvents.ONBOARDING_SETUP_COMPLETED, {
      [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
      [TrackingVariables.PLATFORM]: subscriptionType,
      [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
        Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
      ),
      [TrackingVariables.PLAN_NAME]: user?.one_pricing_plan ? 'One Pricing' : '',
    });

    /**
     * ====
     */
    try {
      let companyId;
      let customerId: string;

      setIsLoading(true);

      if (state?.canBeCreated) {
        const result = await prepareAccount();
        companyId = result.companyId;
        customerId = result.customerId;

        if (!companyId || !customerId) {
          throw new Error("The account hasn't been prepared");
        }
      } else {
        const { body: accounts } = await getMyAccounts();
        companyId = accounts[0]?.companies[0].id;
        customerId = accounts[0]?.chargebeeCustomerId;
      }

      const settingId = (await prepareSetting(companyId)).body.settingId;

      await submitSurvey(SURVEY_ID, customerId);

      if (!settingId) {
        throw new Error("The configuration hasn't been prepared. Please try again");
      }

      trackEvent(TrackingEvents.ONBOARDING_CUSTOMIZATION_COMPLETED, {
        [TrackingVariables.FIRST_LAYER_BANNER_LAYOUT_CUSTOM]:
          customisationForm.getFieldValue('firstLayerVariant') !== CreateSettingBodyFirstLayerVariant.WALL,
        [TrackingVariables.BUTTON_COLOR_CUSTOM]:
          customisationForm.getFieldValue('buttonBackgroundColor') !== colors.primary ||
          customisationForm.getFieldValue('buttonTextColor') !== colors.white,
        [TrackingVariables.PRIVACY_BUTTON_LAYOUT_CUSTOM]:
          customisationForm.getFieldValue('buttonPrivacyVariant') !== CreateSettingBodyButtonPrivacyVariant.fingerprint,
      });

      setCustomerData({ settingId, companyId });
      clearTrialCookies();
      if (subscriptionType === SubscriptionType.app) {
        onFinish();
      } else {
        handleNext();
      }
    } catch (error) {
      message.error("Couldn't prepare account or configuration. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = () => {
    if (customerData?.companyId) {
      navigate(`/company/${customerData?.companyId}/configurations`);
    } else {
      navigate('/companies');
    }
  };

  useEffect(() => {
    const crumbs: string[] = [];
    steps?.forEach((step: any) => {
      if (!step?.hide) {
        crumbs.push(step.breadcrumb);
      }
    });
    setBreadcrumb(crumbs);

    const stepData = steps?.[currentStep];
    setCurrentStepData(stepData);

    const isAllowedBack = currentStep !== 0 && currentStep !== OnboardingSteps.Implementation && state?.canBeCreated;
    setIsAllowedToGoBack(isAllowedBack);
  }, [steps, currentStep, subscriptionType]);

  useEffect(() => {
    setSteps([
      {
        title: 'Create Consent For Your Business',
        breadcrumb: 'Welcome',
        primaryButton: 'Next',
        sidebar: {
          title: 'Welcome to Usercentrics',
          description:
            'Get started by adding your first consent banner configuration. Choose the domain and select which framework you want to suppport.',
        },
        content: (
          <Space direction="vertical" size={24}>
            <DetailsPlatform value={subscriptionType} onChange={setSubscriptionType} />
            <Details form={detailsForm} setDisabled={setDisabled} />
          </Space>
        ),
        onClick:
          subscriptionType === SubscriptionType.app
            ? onCreateAccount
            : () => {
                if (detailsForm.getFieldValue('domain')) {
                  trackEvent(TrackingEvents.ONBOARDING_DOMAIN_ENTERED);
                }
                handleNext();
              },
      },
      {
        hide: subscriptionType === SubscriptionType.app,
        title: 'Customize Consent Banner',
        breadcrumb: 'Consent Customization',
        primaryButton: 'Save and Next',
        sidebar: {
          label: '',
          title: 'Customize your consent banner',
          description: 'Change the design of your consent banner and tailor it to your brand identity.',
        },
        content: <CustomizationStep form={customisationForm} />,
        onClick: onCreateAccount,
      },
      {
        hide: subscriptionType === SubscriptionType.app,
        title: 'Implement Configuration',
        breadcrumb: 'Implementation',
        primaryButton: 'Finish',
        sidebar: {
          title: 'Time to start collecting user consents',
          description: 'Your CMP is ready to be implemented.',
        },
        content: (
          <Implementation
            settingId={customerData?.settingId || ''}
            isTcf={detailsForm?.getFieldValue('framework') === LegalFramework.TCF2}
          />
        ),
        onClick: () => {
          trackEvent(TrackingEvents.ONBOARDING_IMPLEMENTATION_COMPLETED);
          onFinish();
        },
      },
    ]);
  }, [subscriptionType]);

  return (
    <Flex justify="center">
      <Layout style={{ maxWidth: '1440px', padding: 0 }}>
        <Sidebar {...currentStepData?.sidebar} />
        <Layout style={{ padding: 0 }}>
          <Content style={{ padding: '48px 48px 0 48px' }}>
            {isError ? (
              <ErrorState />
            ) : isLoading ? (
              <LoadingState />
            ) : (
              <>
                <Flex vertical gap={16}>
                  <Flex justify="space-between" align="center">
                    {breadCrumb && <DotStepper steps={breadCrumb} activeStep={currentStep} />}
                    <UserDropdown />
                  </Flex>
                  <Title weight={700} fontSize={28}>
                    {currentStepData?.title}
                  </Title>
                </Flex>
                <Content className="scroll" style={{ height: 'calc(100vh - 332px)', paddingTop: 18 }}>
                  {currentStepData?.content}
                </Content>
                <Footer
                  buttons={[
                    {
                      isShown: isAllowedToGoBack,
                      title: 'Back',
                      onClick: handleBack,
                      className: 'onboarding',
                      key: 'back',
                    },
                    {
                      isShown: state?.hasAnySettings && currentStep !== OnboardingSteps.Implementation,
                      title: 'Cancel new trial',
                      onClick: () => {
                        clearTrialCookies();
                        navigate('/');
                      },
                      className: 'onboarding',
                      key: 'cancel_trial',
                    },
                    {
                      isShown: true,
                      type: 'primary',
                      onClick: currentStepData?.onClick,
                      disabled,
                      title: currentStepData?.primaryButton,
                      className: 'onboarding',
                      key: 'next',
                    },
                  ]}
                  showTerms={currentStep === OnboardingSteps.AccountDetails}
                />
              </>
            )}
          </Content>
        </Layout>
        <Survey onSubmit={submitSurvey} surveyId={SURVEY_ID} form={surveyForm} />
      </Layout>
    </Flex>
  );
};

export default Onboarding;
